import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  apiSignIn,
  getLoggedInUser
} from '@lok/services/auth';
import { isValidToken } from '@lok/utils/auth';
import { useCreateLogMutation } from '@lok/services/logs';
import RtkQueryService from '@lok/services/RtkQueryService';
import useModuleStore from '@lok/pages/modules/store/lessonStore';

import {
  signInSuccess,
  signOutSuccess
} from '../store/auth/sessionSlice';

import { setUser } from '../store/auth/userSlice';

const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createLog] = useCreateLogMutation();
  const { removeLessons } = useModuleStore();

  const { token, signedIn } = useSelector(
    (state) => state.auth.session
  );

  const isTokenOK = isValidToken(token);

  const getUser = async () => {
    try {
      const resp = await getLoggedInUser();

      dispatch(setUser(resp.data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const signIn = async (data) => {
    try {
      const resp = await apiSignIn(data);

      const { access: tokenD } = resp.data;

      dispatch(signInSuccess(tokenD));

      if (resp.data) {
        navigate('/dashboard');
      }

      return {
        authenticated: true,
        ...resp
      };
    } catch (error) {
      return {
        authenticated: false,
        status: 'error',
        message: error.message,
        error_object: error?.response?.data
      };
    }
  };

  const handleSignOut = () => {
    dispatch(signOutSuccess());
    dispatch(
      setUser({
        userName: '',
        email: ''
      })
    );
    navigate('/login');
    localStorage.removeItem('activeQuizSession');
  };

  const signOut = async () => {
    /**
     * Remove lessons and module id from store
     * when user logs out. Also reset the api state
     * to avoid data from previous user sessions.
     */
    removeLessons();
    dispatch(RtkQueryService.util.resetApiState());

    await createLog({
      page: 'logout',
      event_type: 'logged_out'
    });
    handleSignOut();
  };

  return {
    authenticated: token && signedIn && isTokenOK,
    getUser,
    signIn,
    signOut,
    token
  };
};

export default useAuth;
