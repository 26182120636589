import { create } from 'zustand';

const initialState = {
  moduleId: null,
  lessons: [],
  totalLessons: 0,
  totalCompletedLessons: 0
};

const useModuleStore = create((set) => ({
  ...initialState,
  removeLessons: () => set(initialState),
  removeModuleId: () => set({ moduleId: null }),

  setModuleId: (id) => set({ moduleId: id }),
  setLessons: (lessons) => set({ lessons }),
  setTotalLessons: (total) => set({ totalLessons: total }),
  setTotalCompletedLessons: (total) =>
    set({ totalCompletedLessons: total }),

  updateLessonBySlug: (slug, completed) =>
    set((state) => ({
      lessons: state.lessons.map((lesson) =>
        lesson.slug === slug
          ? {
              ...lesson,
              has_current_user_completed: completed
            }
          : lesson
      ),
      totalCompletedLessons: state.totalCompletedLessons + 1
    }))
}));

export default useModuleStore;
