import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import store from './store';
import Layout from './components/Layouts/Layout';
import { ThemeProvider } from './context/ThemeContext';
import ErrorBoundary from './components/Utility/ErrorBoundary';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <ThemeProvider>
            <Layout />
            <ToastContainer />
          </ThemeProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
